export default {
  submitProposal: 32000,
  submitProgressReport: 32001,
  sponsor_vote: 32002,
  reject_sponsor: 32003,
  voteProposal: 32004,
  voteProgressReport: 32005,
  updatePeriod: 32006,
  unregisterPrep: 32007,
  registerPrep: 32008,
  payPrepPenalty: 32009,
  approve_sponsor: 32010,
  claimReward: 32011,
  claimSponsorBond: 32012,
  votePriority: 32013,
  submitProposalMock: 32014,
};
