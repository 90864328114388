export default {
  Voting: 1,
  Active: 2,
  Pending: 3,
  Paused: 4,
  Completed: 5,
  Rejected: 6,
  Disqualified: 7,
  Draft: 8,
};
